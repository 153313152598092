<template>
     <section class="page_title big  pdt_80  pdb_40">
               <div class="container">
                  <div class="row">
                     <!---------row----------->
                     <div class="col-lg-12 col-md-12 text-center">
                        <!---------col----------->
                        <div class="content_box">
                           <h6>Recent from the Reports</h6>
                           <h1>  Latest Project Reports</h1>
                           <ul class="bread_crumb text-center">
                              <li class="bread_crumb-item"><a href="#">Home</a></li>
                              <li class="bread_crumb-item"><a href="#">Products</a></li>
                              <li class="bread_crumb-item active">Reports</li>
                           </ul>
                        </div>
                        <!---------col-end---------->
                     </div>
                     <!----------row-end---------->
                  </div>
               </div>
            </section>
            <!-----------page_title-end-------------->
            <!-----------blog_v1--------------->
            <section class="blog_v1 pdt_80  pdb_100">
               <div class="container">
                  <div class="featured_post">
                     <div class="row">
                        <!---------row----------->
                        <div class="col-lg-12">
                           <!---------blog_box----------->
                           <div class="blog_box type_two">
                              <div class="image_box">
                                 <img src="@/assets/image/products/ghana_essential_services.jpg" class="img-fluid" alt="img" />
                                 <div class="overlay ">
                                    <a href="@/assets/image/products/ghana_essential_services.jpg" data-fancybox="gallery " data-caption=" ">
                                    <span class="linearicons-picture2"></span>
                                    </a>
                                 </div>
                              </div>
                              <div class="content_box clearfix">
                                 <h2><a href="#">Cross Country Synthesis On Maintenance Of Essential Health Services</a></h2>
                                 <p>This report explains the disruptions in essential health services because people where afraid of getting sick with Covid 19.
                                 
                               <b>Read maintenance of essential health services case studies of
                               <a target="_blank" href="https://www.exemplars.health/emerging-topics/epidemic-preparedness-and-response/essential-health-services/uganda">Uganda</a>,
                               <a target="_blank" href="https://www.exemplars.health/emerging-topics/epidemic-preparedness-and-response/essential-health-services/democratic-republic-of-the-congo">DRC</a>,
                                <a target="_blank" href="https://www.exemplars.health/emerging-topics/epidemic-preparedness-and-response/essential-health-services/senegal">Senegal</a> and 
                                <a target="_blank" href="https://www.exemplars.health/emerging-topics/epidemic-preparedness-and-response/essential-health-services/nigeria">Nigeria</a></b>  
                              </p>
                                 <a href="https://www.exemplars.health/emerging-topics/epidemic-preparedness-and-response/essential-health-services/cross-country-synthesis" target="_blank" class="read_more tp_one">Read More <span class="linearicons-arrow-right"></span></a>
                              </div>
                           </div>
                           <!---------blog_box end----------->
                        </div>
                        <!---------row-end---------->
                     </div>
                  </div>
                  <div class="row">
                     <!---------row----------->
                    <!----- <div class="col-lg-4">------>
                        <!---------blog_box----------->
                        <!-- <div class="blog_box type_one">
                           <div class="image_box">
                              <img src="@/assets/image/products/instead.jpeg" class="img-fluid" alt="img" />
                              <div class="overlay">
                                 <a href="@/assets/image/products/instead.jpeg" data-fancybox="gallery " data-caption=" ">
                                    <span class="linearicons-picture2"></span>
                                 </a>
                              </div>
                           </div>
                           <div class="content_box">
                              <h2><a href="#">CROSS COUNTRY POST SPRINT-REFRESHER REPORT FOR DRC, NIGERIA, SENEGAL, AND UGANDA </a></h2>
                              
                              <p>Following submission of the Testing and surveillance Sprint report at the end of March 2021, it was agreed that the four partner countries (DRC, Nigeria, Senegal and Uganda) continue to track and document key events in testing and surveillance 
                              
                              </p>
                              <a href="/assets/image/products/ECR Testing _ Surveillance Sprint -UGANDA_ Clean_NOV 12 .pdf" class="read_more tp_one">Download<span class="linearicons-arrow-down"></span></a>
                           </div>
                        </div> -->
                        <!---------blog_box end----------->
                     <!--------</div>------>
                 
                  <div class="col-lg-4">
                     <!---------blog_box----------->
                     <div class="blog_box type_one">
                        <div class="image_box">
                           <img src="@/assets/image/products/subsharan.jpg" class="img-fluid" alt="img" />
                           <div class="overlay">
                              <a href="@/assets/image/products/subsharan.jpg" data-fancybox="gallery " data-caption=" ">
                                 <span class="linearicons-picture2"></span>
                              </a>
                           </div>
                        </div>
                        <div class="content_box">
                           <h2><a href="#">TESTING AND SURVEILLANCE OF COVID 19 IN SUB-SAHARAN AFRICA(UGANDA, DRC, NIGERIA, SENEGAL)</a></h2>
                           
                           <p>Between March 21, 2020, and June 30, 2021, over 1.3 million COVID-19 tests were conducted in around Sub-Saharan Africa including countries like Uganda, DRC, Nigeria and Senegal. Read the reports of each country
                              <a target="_blank" href="https://www.exemplars.health/emerging-topics/epidemic-preparedness-and-response/testing-and-surveillance/uganda">Uganda</a>,
                              <a target="_blank" href="https://www.exemplars.health/emerging-topics/epidemic-preparedness-and-response/testing-and-surveillance/drc?requestedPdfUrl=%2fapi%2fPdfHandler%2fDownloadPdf%3fid%3d%7b436A05AA-6238-4CC1-A24B-364FF9177EC4%7d%26lang%3den&requestedPdfTitle=TSDRCLongformFrench2022R2&login=true">DRC</a>,
                              <a target="_blank" href="https://www.exemplars.health/emerging-topics/epidemic-preparedness-and-response/testing-and-surveillance/senegal">Senegal</a> and 
                              <a target="_blank" href="https://www.exemplars.health/emerging-topics/epidemic-preparedness-and-response/testing-and-surveillance/nigeria">Nigeria</a></p>
                           <a target="_blank" href="https://www.exemplars.health/emerging-topics/epidemic-preparedness-and-response/testing-and-surveillance/cross-country-synthesis" class="read_more tp_one">Read More<span class="linearicons-arrow-right"></span></a>
                        </div>
                     </div>
                     <!---------blog_box end----------->
                  </div>



                  



                  <div class="col-lg-4">
                        <!---------blog_box----------->
                        <div class="blog_box type_one">
                           <div class="image_box">
                              <img src="@/assets/image/products/subsharan.jpg" class="img-fluid" alt="img" />
                              <div class="overlay">
                                 <a href="@/assets/image/products/subsharan.jpg" data-fancybox="gallery " data-caption=" ">
                                    <span class="linearicons-picture2"></span>
                                 </a>
                              </div>
                           </div>
                           <div class="content_box">
                              <h2><a href="#">TESTING AND SURVEILLANCE ON CROSS COUNTRY SYNTHESIS ABOUT COVID 19 IN SUB-SAHARAN AFRICA</a></h2>
                              
                              <p>This cross country synthesis presents high level strategies, lessons and challenges that emerged from the research which readers can explore further in the detailed case studies.<b> Read testing and surveillance case studies of
                               <a target="_blank" href="https://www.exemplars.health/emerging-topics/epidemic-preparedness-and-response/testing-and-surveillance/uganda">Uganda</a>,
                               <a target="_blank" href="https://www.exemplars.health/emerging-topics/epidemic-preparedness-and-response/testing-and-surveillance/drc?requestedPdfUrl=%2fapi%2fPdfHandler%2fDownloadPdf%3fid%3d%7b436A05AA-6238-4CC1-A24B-364FF9177EC4%7d%26lang%3den&requestedPdfTitle=TSDRCLongformFrench2022R2&login=true">DRC</a>,
                                <a target="_blank" href="https://www.exemplars.health/emerging-topics/epidemic-preparedness-and-response/testing-and-surveillance/senegal">Senegal</a> and 
                                <a target="_blank" href="https://www.exemplars.health/emerging-topics/epidemic-preparedness-and-response/testing-and-surveillance/nigeria">Nigeria</a></b></p>
                              <a target="_blank" href="https://www.exemplars.health/emerging-topics/epidemic-preparedness-and-response/testing-and-surveillance/cross-country-synthesis" class="read_more tp_one">Read More<span class="linearicons-arrow-right"></span></a>
                           </div>
                        </div>
                        <!---------blog_box end----------->
                     </div>
                 
                     <!----------row-end---------->
                  </div>
            
               </div>
            </section>
            <!-----------blog_v1-end-------------->
       
</template>